import { ApiSourceOfTruth, ApiSourceOfTruthSchema, EnvironmentId, ReportId, TagId } from 'api/apiTypes';
import { ApiSourceOfTruthPageFilter, ApiSourceOfTruthPageFilterSchema } from 'api/apiTypes/sourceOfTruth/pageFilters';
import routes from 'api/routes';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import { CurrentUserContext } from 'api/useCurrentUser';
import { ReportContext } from 'api/useReport';
import { error, isErrored, isLoading, loading, useRegisterMiddlewareHook } from 'api/utils';
import { Moment } from 'moment';
import { useContext, useEffect } from 'react';
import useSWR from 'swr';
import { z } from 'zod';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export * from './summary';
export * from './qualifiers';
export * from './ignoredValues';
export * from './customizations';
export * from './ignoredVariables';
export * from './missingTagRules';

export function useSourceOfTruth(
  reportId: ReportId,
  tagId: TagId,
  date?: Moment,
  limit?: number
): ApiSourceOfTruth | ApiError | ApiLoading {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const sourceOfTruth = sourceOfTruthContext.sourceOfTruthCache[tagId];

  useEffect(() => {
    if (currentReport && tagId && !sourceOfTruth)
      sourceOfTruthContext.getSourceOfTruth(currentReport, tagId, false, date, limit);
  }, [currentReport, date, limit, reportId, sourceOfTruth, sourceOfTruthContext, tagId]);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(sourceOfTruth)) return loading();
  if (isErrored(sourceOfTruth)) return error(sourceOfTruth.messages);

  return sourceOfTruth;
}

export function useGlobalSourceOfTruth(
  reportId: ReportId,
  tagId: TagId,
  date?: Moment,
  limit?: number
): ApiSourceOfTruth | ApiError | ApiLoading {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const key = `${tagId}-${date?.format('MM-DD')}`;
  const sourceOfTruth = sourceOfTruthContext.globalSourceOfTruthCache[key];

  useEffect(() => {
    if (currentReport && tagId && !sourceOfTruth)
      sourceOfTruthContext.getSourceOfTruth(currentReport, tagId, true, date, limit);
  }, [currentReport, date, limit, reportId, sourceOfTruth, sourceOfTruthContext, tagId]);

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(sourceOfTruth)) return loading();
  if (isErrored(sourceOfTruth)) return error(sourceOfTruth.messages);

  return sourceOfTruth;
}

export function usePaginateGlobalSourceOfTruth(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const { get } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (!tagId) return error();

  const envGuid = currentReport.summary.json.environmentId;

  const paginate = async (_page: number, _pageSize: number, date?: Moment) => {
    const payload = await get(routes.sourceOfTruth.getGlobalSourceOfTruth(envGuid, tagId, date), {
      withCredentials: true,
    });

    const data = ApiSourceOfTruthSchema.parse(payload.data);
    const newSotData = {
      ...data,
      rules: data.rules.map((x) => ({
        ...x,
        groupPathFriendly: x.groupPathTranslations.technicalName
          ? `${x.groupPathTranslations.friendlyName} (${x.groupPathTranslations.technicalName})`
          : x.groupPathTranslations.friendlyName,
      })),
    };
    return newSotData;
  };

  return paginate;
}

export function useFilterPageSourceOfTruth(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const { post } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  const envGuid = currentReport.summary.json.environmentId;

  const filter = async (
    filters: ApiSourceOfTruthPageFilter[],
    page: number,
    pageSize: number,
    summaryFilter?: 'ALL' | 'NONE' | 'AUTO' | 'CUSTOM',
    date?: Moment
  ) => {
    const data = ApiSourceOfTruthPageFilterSchema.array().parse(filters);
    const payload = await post(
      routes.sourceOfTruth.getPageSourceOfTruth(envGuid, tagId, page, pageSize, summaryFilter, date),
      {
        filters: data,
      },
      {
        withCredentials: true,
      }
    );
    const filterResponseSchema = z
      .object({
        ...ApiSourceOfTruthSchema.shape,
        rules: z.array(
          z.object({
            ...ApiSourceOfTruthSchema.shape.rules.element.shape,
            pageUrl: z.string(),
            groupPath: z.union([z.array(z.string()), z.string()]),
            groupPathFriendly: z.object({
              friendlyName: z.string(),
              technicalName: z.string().optional(),
            }),
            groupPathTranslations: z.undefined(),
            pageViewsWithVariable: z.number().optional(),
            pageViewsWithTag: z.number().optional(),
            percentValuePopulated: z.number().optional(),
          })
        ),
        totalPages: z.union([z.number().optional(), z.null()]),
        totalRows: z.union([z.number().optional(), z.null()]),
      })
      .transform((data) => ({
        ...data,
        rules: data.rules.map((x) => ({
          ...x,
          groupPathTranslations: x.groupPathFriendly,
        })),
      }));
    const newSotData = filterResponseSchema.parse(payload.data);
    const sotData = newSotData.rules.map((rule) => ({
      ...rule,
      groupPathFriendly: rule.groupPathTranslations.technicalName
        ? `${rule.groupPathTranslations.friendlyName} (${rule.groupPathTranslations.technicalName})`
        : rule.groupPathTranslations.friendlyName,
      groupPath: typeof rule.groupPath === 'string' ? rule.groupPath : rule.groupPath.join(';'),
    }));

    return {
      data: sotData,
      totalPages: newSotData.totalPages,
      totalRows: newSotData.totalRows,
    };
  };

  return filter;
}

const autocompleteRawResponseSchema = z.record(
  z.object({
    friendlyName: z.string(),
    technicalName: z.string(),
  })
);

const autocompleteResponseSchema = z.object({
  groupPath: z.string(),
  friendlyName: z.string(),
  technicalName: z.string(),
});

export function useAutocompleteSourceOfTruthGroupPath(reportId: ReportId, tagId: TagId, date: Moment) {
  const { get } = useAxios();

  const currentReport = useRegisterMiddlewareHook('useAutocompleteSourceOfTruthGroupPath', reportId);

  const envGuid = currentReport?.summary.json.environmentId;

  return useSWR(
    routes.sourceOfTruth.autocompleteGroupPaths(envGuid ?? ('' as EnvironmentId), tagId, '', date),
    async (url) => {
      if (isErrored(currentReport) || !envGuid || !tagId) throw new Error();
      if (isLoading(currentReport)) return [];
      const rawData = autocompleteRawResponseSchema.parse(
        (
          await get(url, {
            withCredentials: true,
          })
        ).data
      );

      const groupPaths: z.infer<typeof autocompleteResponseSchema>[] = Object.keys(rawData).map((key) => ({
        groupPath: key,
        ...rawData[key],
      }));

      // eslint-disable-next-line sentinelinsights/no-mutations
      return autocompleteResponseSchema
        .array()
        .parse(groupPaths)
        .sort((a, b) =>
          `${a.friendlyName} (${a.technicalName})`.localeCompare(`${b.friendlyName} (${b.technicalName})`)
        );
    },
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );
}
