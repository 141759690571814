import React from 'react';

import InternalToolsOverlaysCurrentlyDownloadedReports from './CurrentlyDownloadedReports';

export default [
  {
    name: 'Currently Downloaded Reports',
    id: 'currently-downloaded-reports',
    description: "This overlay will show you the names and id's of the currently downloaded (cached) reports.",
    location: 'Navbar',
    icon: 'C',
    component: <InternalToolsOverlaysCurrentlyDownloadedReports />,
  },
];
