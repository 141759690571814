//import { SourceOfTruthContext } from '.';

import { ReportId, TagId } from 'api/apiTypes';
import ApiSourceOfTruthQualifier, { ApiSourceOfTruthQualifierSchema } from 'api/apiTypes/sourceOfTruth/qualifier';
import routes from 'api/routes';
import { CurrentUserContext } from 'api/useCurrentUser';
import { ReportContext } from 'api/useReport';
import { error, isErrored, isLoading, loading, withoutError } from 'api/utils';
import { useContext } from 'react';
import useSWR, { mutate, useSWRConfig } from 'swr';

import { useAxios } from 'utils/transport/useAxios';

import { SourceOfTruthContext } from './provider';

export function useSourceOfTruthQualifiers(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  // const sourceOfTruthContext = useContext(SourceOfTruthContext);

  const { get } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const envGuid = withoutError(currentReport)?.summary.json.environmentId;

  // const qualifiers = sourceOfTruthContext.qualifiersCache[tagId];

  const response = useSWR(
    envGuid ? routes.sourceOfTruth.qualifiers.get(envGuid, tagId) : null,
    async (url) => {
      const response = await get(url, {
        withCredentials: true,
      });

      const data = ApiSourceOfTruthQualifierSchema.array().parse(response.data);

      return data;
    },
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      shouldRetryOnError: false,
      errorRetryCount: 0,
      errorRetryInterval: 0,
    }
  );

  if (response.error) return error();
  if (response.isLoading || response.isValidating) return loading();

  return response.data;

  // useEffect(() => {
  //   if (currentReport && tagId && !qualifiers) sourceOfTruthContext.getQualifiers(currentReport, tagId);
  // }, [currentReport, reportId, sourceOfTruthContext, tagId, qualifiers]);

  // if (isLoading(currentReport)) return loading();
  // if (isErrored(currentReport)) return error();

  // if (isLoading(qualifiers)) return loading();
  // if (isErrored(qualifiers)) return error(qualifiers.messages);

  // return qualifiers;
}

export function useCreateSourceOfTruthQualifier(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  // const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { post } = useAxios();
  const { mutate } = useSWRConfig();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  // const qualifiers = sourceOfTruthContext.qualifiersCache[tagId];

  const envGuid = withoutError(currentReport)?.summary.json.environmentId;
  const url = envGuid ? routes.sourceOfTruth.qualifiers.get(envGuid, tagId) : null;

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  // if (isLoading(qualifiers)) return loading();
  // if (isErrored(qualifiers)) return error(qualifiers.messages);

  const create = async (qualifier: ApiSourceOfTruthQualifier) => {
    const _data = ApiSourceOfTruthQualifierSchema.parse(qualifier);
    const data = _data;
    await post(routes.sourceOfTruth.qualifiers.create(currentReport.summary.json.environmentId, tagId), data, {
      withCredentials: true,
    });

    if (url) mutate(url);
    // sourceOfTruthContext.setQualifiersCache((prev) => ({
    //   ...prev,
    //   [tagId]: undefined, //withoutError(prev[tagId])?.concat(_data),
    // }));
    // sourceOfTruthContext.qualifierIds.current?.delete(tagId);
  };

  return create;
}

export function useUpdateSourceOfTruthQualifier(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  // const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { patch } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  // const qualifiers = sourceOfTruthContext.qualifiersCache[tagId];

  const envGuid = withoutError(currentReport)?.summary.json.environmentId;
  const url = envGuid ? routes.sourceOfTruth.qualifiers.get(envGuid, tagId) : null;

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  // if (isLoading(qualifiers)) return loading();
  // if (isErrored(qualifiers)) return error(qualifiers.messages);

  const update = async (qualifier: ApiSourceOfTruthQualifier) => {
    const _data = ApiSourceOfTruthQualifierSchema.parse(qualifier);
    const data = _data;
    await patch(
      routes.sourceOfTruth.qualifiers.update(currentReport.summary.json.environmentId, tagId, data.id),
      data,
      {
        withCredentials: true,
      }
    );

    if (url) mutate(url);

    // sourceOfTruthContext.setQualifiersCache((prev) => ({
    //   ...prev,
    //   [tagId]: undefined,
    // }));
    // sourceOfTruthContext.qualifierIds.current?.delete(tagId);
  };

  return update;
}

export function useDeleteSourceOfTruthQualifier(reportId: ReportId, tagId: TagId) {
  const context = useContext(ReportContext);
  const userContext = useContext(CurrentUserContext);
  const sourceOfTruthContext = useContext(SourceOfTruthContext);
  const { delete: deleteRequest } = useAxios();

  const currentReport = userContext.reportIds.has(reportId) ? context.reportCache[reportId] : loading();

  const qualifiers = sourceOfTruthContext.qualifiersCache[tagId];

  if (isLoading(currentReport)) return loading();
  if (isErrored(currentReport)) return error();

  if (isLoading(qualifiers)) return loading();
  if (isErrored(qualifiers)) return error(qualifiers.messages);

  const update = async (qualifierId: string) => {
    await deleteRequest(
      routes.sourceOfTruth.qualifiers.delete(currentReport.summary.json.environmentId, tagId, qualifierId),
      {
        withCredentials: true,
      }
    );
    sourceOfTruthContext.setQualifiersCache((prev) => ({
      ...prev,
      [tagId]: withoutError(prev[tagId])?.filter((q) => q.id !== qualifierId),
    }));
  };

  return update;
}
