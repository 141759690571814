import { ChevronLeft, ChevronRight } from '@mui/icons-material';
import { IconButton } from '@mui/material';
import React from 'react';

import * as Styled from './ScrollContainer.styles';
import type { ScrollContainerProps } from './ScrollContainer.types';
import useScrollContainer from './useScrollContainer';

export default function ScrollContainer(props: ScrollContainerProps) {
  const {
    variant,
    children,
    containerRef,
    canScrollLeft,
    canScrollRight,
    onScrollLeftClick,
    onScrollRightClick,
    rootStyle,
    ...rest
  } = useScrollContainer(props);

  return (
    <Styled.Container variant={variant} style={rootStyle}>
      <IconButton disabled={!canScrollLeft} onClick={onScrollLeftClick}>
        <ChevronLeft />
      </IconButton>
      <Styled.Scroller ref={containerRef} {...rest}>
        {children}
      </Styled.Scroller>
      <IconButton disabled={!canScrollRight} onClick={onScrollRightClick}>
        <ChevronRight />
      </IconButton>
    </Styled.Container>
  );
}
