import React, { useCallback, useEffect } from 'react';

import { ScrollContainerProps } from './ScrollContainer.types';

export default function useScrollContainer(props: ScrollContainerProps) {
  const { scrollType = 'fixed', fixedScrollAmount = 200, variant = 'contained', children, ...rest } = props;
  const containerRef = React.useRef<HTMLDivElement>(null);
  const [canScrollLeft, setCanScrollLeft] = React.useState(false);
  const [canScrollRight, setCanScrollRight] = React.useState(false);
  const latchIndex = React.useRef(0);

  const onscroll = useCallback(() => {
    if (containerRef.current) {
      const c = containerRef.current;
      setCanScrollLeft(c.scrollLeft > 0);
      setCanScrollRight(c.scrollWidth > c.offsetWidth && c.scrollLeft < c.scrollWidth - c.offsetWidth);
    }
  }, [setCanScrollLeft, setCanScrollRight]);
  //#endregion

  //#region effects
  // set initial scroll button states
  useEffect(() => onscroll(), [onscroll]);

  // add scroll event listener
  useEffect(() => {
    const c = containerRef.current;
    c?.addEventListener('scroll', onscroll);
    c?.addEventListener('resize', onscroll);
    return () => {
      c?.removeEventListener('scroll', onscroll);
      c?.removeEventListener('resize', onscroll);
    };
  }, [onscroll]);

  // reset scroll position when scrollType changes
  useEffect(() => {
    if (scrollType === 'latched' && containerRef.current) {
      latchIndex.current = 0;
      containerRef.current.scrollLeft = 0;
      containerRef.current.setAttribute('data-targetleft', '0');
    }
  }, [scrollType]);
  //#endregion

  const onScrollLeftClick = useCallback(() => {
    if (!containerRef.current || !canScrollLeft) return;
    scroll(containerRef.current, scrollType, latchIndex.current, 'left', fixedScrollAmount);
  }, [fixedScrollAmount, scrollType, canScrollLeft]);

  const onScrollRightClick = useCallback(() => {
    if (!containerRef.current || !canScrollRight) return;
    scroll(containerRef.current, scrollType, latchIndex.current, 'right', fixedScrollAmount);
  }, [fixedScrollAmount, scrollType, canScrollRight]);

  return {
    variant,
    children,
    containerRef,
    canScrollLeft,
    canScrollRight,
    onScrollLeftClick,
    onScrollRightClick,
    ...rest,
  };
}

type ScrollFunction = (
  element: HTMLElement,
  scrollType: ScrollContainerProps['scrollType'],
  latchIndex: number,
  direction: 'left' | 'right',
  fixedScrollAmount: number
) => void;

const scroll: ScrollFunction = (element, scrollType, latchIndex, direction, fixedScrollAmount) => {
  if (!element) return;

  /* 
    data-targetleft holds the final scroll position. Because the browser will 
    animate the scrollLeft, its value is not the same as the final scroll position.
  */
  if (!element.hasAttribute('data-targetleft')) element.setAttribute('data-targetleft', element.scrollLeft.toString());

  // left refers to the final scroll position
  const left = +(element.getAttribute('data-targetleft') ?? element.scrollLeft);

  const maxScrollAmount = element.scrollWidth - element.clientWidth;

  // The direction as a math.sign value
  const directionSign = direction === 'right' ? 1 : -1;

  // prevents scrolling out of bounds
  if (
    // check for ability to scroll right
    (left >= maxScrollAmount && direction === 'right') ||
    // check for ability to scroll left
    ((left === 0 || element.scrollLeft === 0) && direction === 'left')
  )
    return;

  switch (scrollType) {
    case 'latched':
      // if the latchindex (selected / focused child) is the first, we can't scroll left
      if (latchIndex === 0 && direction === 'left') return;

      const childrenElems = Array.from(element.children);
      const child1 = childrenElems[latchIndex] as HTMLElement;
      const child2 = childrenElems[latchIndex + directionSign] as HTMLElement;
      const latchedAmount = Math.max(
        Math.min(
          left +
            Math.abs(child1.offsetLeft - child2.offsetLeft - (child1.offsetLeft - element.scrollLeft)) * directionSign,
          maxScrollAmount
        ),
        0
      );
      element.scrollTo({ left: latchedAmount, behavior: 'smooth' });
      element.setAttribute('data-targetleft', latchedAmount.toString());
      if (latchedAmount < maxScrollAmount) latchIndex += directionSign;
      break;
    case 'fixed':
    default:
      const fixedAmount = Math.max(Math.min(left + fixedScrollAmount * directionSign, maxScrollAmount), 0);
      element.scrollTo({ left: fixedAmount, behavior: 'smooth' });
      element.setAttribute('data-targetleft', fixedAmount.toString());
      break;
  }
};
