import { MenuItem } from '@mui/material';
import React, { useEffect, useState } from 'react';

import CaptionedSelect, { CaptionedSelectProps } from '.';

export function Demo(props: CaptionedSelectProps & { children: { label: string }[] }) {
  const { children, label, variant, ...rest } = props;
  const isMinimal = variant === 'minimal';
  const [value, setValue] = useState<number | null>(isMinimal ? 0 : null);

  useEffect(() => {
    if (isMinimal && value === null) setValue(0);
  }, [isMinimal, value]);

  return (
    <CaptionedSelect
      value={value ?? ''}
      sx={{ minWidth: 250 }}
      onChange={(e) => setValue(parseInt(e.target.value))}
      {...rest}
      variant={variant}
      label={isMinimal ? undefined : label}
    >
      {children.map((option, index) => (
        <MenuItem key={index} value={index}>
          {option.label}
        </MenuItem>
      ))}
    </CaptionedSelect>
  );
}
