export const baseURL = process.env.REACT_APP_API_URL || 'http://localhost:5003/';

export const APP_URLS = {
  userLogin: () => '/auth/login',
  userSSOLogin: (code: string) => `/auth/tokens-from-code/${code}`,
  userLogout: () => '/auth/logout',
  currentUser: () => `/auth/me`,
  forgetPassword: () => `/auth/forget-password`,
  validateOTP: () => `/auth/validate-otp`,
  setNewPassword: () => `/auth/set-password`,
  signUpUser: () => `/auth/confirm-user`,
  getProfile: () => `/auth/profile`,
  getReport: (reportId: string) => `/auth/report/${reportId}`,
  getExistingRules: (environmentId: string, insightId: string) => `/feedbackloop/find/${environmentId}/${insightId}`,
  deleteRule: () => `/feedbackloop/delete`,
  saveRule: () => `/feedbackloop/save`,
  createDataExport: () => `/dataExport/create`,
};
