import AdminProvider from 'api/admin/provider';
import { CacheProvider } from 'api/cache';
import InternalAdminProvider from 'api/internalAdmin/provider';
import useLogs, { LogProvider } from 'api/logs';
import NotificationsProvider from 'api/notifications/provider';
import PrivacyProviders from 'api/privacy/providers';
import { CurrentUserProvider } from 'api/useCurrentUser';
import { ReportProvider } from 'api/useReport';
import React, { useEffect } from 'react';

import { NewDataExportProvider } from 'pages/data-export/dataExport.provider';

import InternalToolsProvider from './internalProvider';

const requiredProviders = [LogProvider, CacheProvider];

// TODO: add any more custom providers to this list
const customProviders = [
  CurrentUserProvider,
  ReportProvider,
  PrivacyProviders,
  InternalToolsProvider,
  InternalAdminProvider,
  NotificationsProvider,
  AdminProvider,
  NewDataExportProvider,
];

// reverse the order of the providers so that the last one is the first one to be rendered
// eslint-disable-next-line sentinelinsights/no-mutations
const providers = requiredProviders.concat(customProviders).reverse();

export default function ApiProvider({ children }: React.PropsWithChildren) {
  const childContent = (
    <>
      <LogApi />
      {children}
    </>
  );
  // eslint-disable-next-line @typescript-eslint/naming-convention
  return providers.reduce((acc, Provider) => <Provider>{acc}</Provider>, childContent);
}

function LogApi() {
  const { log } = useLogs();

  useEffect(() => {
    log('[API] started');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return null;
}
