import { GTMProvider as ReactGTMProvider } from '@elgorditosalsero/react-gtm-hook';
import { ISnippetsParams } from '@elgorditosalsero/react-gtm-hook/dist/models/GoogleTagManager';
import { CssBaseline, ThemeProvider /*useMediaQuery*/ } from '@mui/material';
import useExperiments from 'api/experiments';
import AuthenticatedRoute from 'containers/authenticated-router';
import getPublicRoutes from 'containers/public-router/routes';
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { theme, themeDark } from 'theme';

import { AppRoutes } from 'config/app-routes';
import { AuthProvider } from 'hooks/useAuth';
import { NotificationProvider } from 'hooks/useNotify';
import NotFound from 'pages/404';
import getAdminRoutes from 'pages/admin/routes';
import getComponentsRoutes from 'pages/components/routes';
import getDataExportRoutes from 'pages/data-export/routes';
import getExploreRoutes from 'pages/explore/routes';
import getInternalRoutes from 'pages/internal/routes';
import getKnowledgeBaseRoutes from 'pages/knowledge-base/routes';
import getNotificationRoutes from 'pages/notifications/routes';
import getPrivacyRoutes from 'pages/privacy/routes';
import getTechnologyRoutes from 'pages/technology/routes';
import { AxiosProvider } from 'utils/transport/axios.context';

const gtmParams: ISnippetsParams = {
  id: process.env.REACT_APP_GTM_ID ?? 'GTM-58BS597',
  environment: {
    gtm_auth: process.env.REACT_APP_GTM_AUTH ?? '',
    gtm_preview: process.env.REACT_APP_GTM_PREVIEW ?? '',
  },
};

const lazily = (page: string) => {
  return React.lazy(() => import(`pages/${page}`));
};

const Dashboard = lazily('dashboard');
const InsightsPage = lazily('insights');

const GTMProvider = process.env.NODE_ENV === 'production' ? ReactGTMProvider : (_: any) => <>{_.children}</>;

const App = () => {
  const darkMode = false; //useMediaQuery('(prefers-color-scheme: dark)');
  const { isExperimentEnabled } = useExperiments();

  return (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <NotificationProvider>
        <CssBaseline />
        <Router>
          <GTMProvider state={gtmParams}>
            <AxiosProvider>
              <AuthProvider>
                <Routes>
                  {/* Authenticated routes automatically redirect to a login page if not logged in */}
                  <Route path="/" element={<AuthenticatedRoute />}>
                    {getAdminRoutes(lazily, isExperimentEnabled)}
                    {getPrivacyRoutes(lazily, isExperimentEnabled)}
                    {getInternalRoutes(lazily, isExperimentEnabled)}
                    {getTechnologyRoutes(lazily, isExperimentEnabled)}
                    {getComponentsRoutes(lazily, isExperimentEnabled)}
                    {getNotificationRoutes(lazily, isExperimentEnabled)}
                    {getExploreRoutes(lazily, isExperimentEnabled)}
                    {getKnowledgeBaseRoutes(lazily, isExperimentEnabled)}
                    {getDataExportRoutes(lazily, isExperimentEnabled)}
                    {/* Change later */}
                    <Route path={AppRoutes.DASHBOARD} element={<Dashboard />} />
                    <Route path={AppRoutes.INSIGHTS} element={<InsightsPage />} />
                    <Route path="*" element={<NotFound />} />
                  </Route>

                  {getPublicRoutes(lazily, isExperimentEnabled)}
                </Routes>
              </AuthProvider>
            </AxiosProvider>
          </GTMProvider>
        </Router>
      </NotificationProvider>
    </ThemeProvider>
  );
};

export type RouteListGetter = (
  lazily: (page: string) => React.LazyExoticComponent<React.ComponentType<any>>,
  isExperimentEnabled: (experiment: string) => boolean
) => JSX.Element;

export default App;
