import { useGTMDispatch } from '@elgorditosalsero/react-gtm-hook';
import useExperiments from 'api/experiments';
import useLogs from 'api/logs';
import Routes from 'api/routes';
import ApiError from 'api/types/base/apiError';
import ApiLoading from 'api/types/base/apiLoading';
import ReportId from 'api/types/report/id';
import PartialReport from 'api/types/report/partialReport';
import type User from 'api/types/user';
import type ApiUser from 'api/types/user/api';
import { ApiUserSchema } from 'api/types/user/api';
import type CurrentUserContextType from 'api/types/user/context';
import { error, isErrored, once } from 'api/utils';
import React, { useContext, useEffect, useMemo, useState } from 'react';

import useAuth from 'hooks/useAuth';
import { useAxios } from 'utils/transport/useAxios';

export const CurrentUserContext = React.createContext<CurrentUserContextType>({} as any);

export function CurrentUserProvider({ children }: React.PropsWithChildren) {
  const { log } = useLogs();
  const { get } = useAxios();
  const store = useExperiments();
  const { logout: authLogout } = useAuth();
  let sendGTM = (..._: any) => {
    return;
  };
  if (process.env.NODE_ENV !== 'development') {
    sendGTM = useGTMDispatch();
  }

  const [hasConsumer, setHasConsumer] = useState(false);
  const [currentUser, setCurrentUser] = useState<ApiUser | ApiError | ApiLoading>();

  const [reports, setReports] = useState<PartialReport[]>([]);
  const [reportIds, setReportIds] = useState<Set<ReportId>>(new Set());

  const logout = authLogout;

  once(
    () => {
      const route = Routes.userInfo();
      get(route, {
        withCredentials: true,
      })
        .then((response) => {
          if (!response.data) throw new Error();
          const user = ApiUserSchema.parse(response.data);

          setCurrentUser(user);
          // ideally the experiments would be passed through the user object
          // but for now we'll just use the store
          /* eslint-disable @typescript-eslint/naming-convention */
          const isInternalUser = user.email.endsWith('@sentinelinsights.com'); // || user.email.endsWith('@datasentinelai.com'),
          const isPowerUser = user.is_power_user === true;
          const allowSOTPublish = (isInternalUser || user.featureFlags?.sotPublish) ?? false;
          const isSotAdmin = isInternalUser;
          store.loadExperiments({
            'user.isSotAdmin': isSotAdmin,
            'user.isInternal': isInternalUser,
            'user.allowPublish': allowSOTPublish,
            'user.isPowerUser': isInternalUser || isPowerUser,
            'pages.internalAdmin.visible': isInternalUser || isPowerUser,
            // Explore Page
            // 'pages.explore.enabled': isInternalUser,
            // 'pages.explore.visible': isInternalUser,
          });
          /* eslint-enable */

          sendGTM({
            event: 'profile_loaded',
            user_email: user.email,
            client_org: user.organization,
          });

          if (user.reports.length) {
            // eslint-disable-next-line sentinelinsights/no-mutations
            const sortedReports = user.reports.sort((a, b) => (a.name < b.name ? -1 : 1));
            setReports(sortedReports as PartialReport[]);
            setReportIds(new Set(sortedReports.map((r) => r.id as ReportId)));
          } else {
            setReports([]);
            setReportIds(new Set());
          }
        })
        .catch(() => {
          log(`[FETCH] no-data: ${route}`, 'Medium');
          setCurrentUser(error());
        });
    },
    [get, log],
    hasConsumer
  );

  const user = useMemo<CurrentUserContextType['user']>(() => {
    if (!isErrored(currentUser) && currentUser) {
      return transformApiUser(currentUser, logout);
    } else {
      return currentUser;
    }
    // having log as a dep is not required a the moment.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser]);

  const value: CurrentUserContextType = {
    user,
    reports,
    reportIds,
    hasConsumer,
    setHasConsumer,
  };

  return <CurrentUserContext.Provider value={value}>{children}</CurrentUserContext.Provider>;
}

function transformApiUser(apiUser: ApiUser, logout: User['logout']): User {
  return {
    id: apiUser.id,
    logout: logout,
    email: apiUser.email,
    lastName: apiUser.last_name,
    firstName: apiUser.first_name,
    organization: apiUser.organization,
    defaultEnvironment: apiUser.defaultEnvironment,
    is_power_user: apiUser.is_power_user ?? false,
  };
}

export default function useCurrentUser(): CurrentUserContextType['user'] {
  const context = useContext(CurrentUserContext);

  useEffect(() => {
    if (!context.hasConsumer) {
      context.setHasConsumer(true);
    }
    if (context.user) {
    }
  }, [context]);

  return context.user;
}
