import ApiProvider from 'api/provider';
import { Header } from 'components';
import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';

import useAuth from 'hooks/useAuth';
import LoaderPage from 'pages/loader';

/**
 * @summary AuthenticatedRoute is a component which navigate to user back to login page in user is not loggedIn
 * @function
 * @name AuthenticatedRoute
 * @return {ReactJSXElement} jsx component
 */
const AuthenticatedRoute = () => {
  const { user } = useAuth();

  if (!user) {
    const currentPage = window.location.href.slice(window.location.origin.length + 1);
    const query = new URLSearchParams({
      redirect: currentPage,
    });
    const queryString = currentPage.length > 1 ? `?${query}` : '';
    return <Navigate to={`/login${queryString}`} />;
  }

  return (
    <ApiProvider>
      <Header>
        <React.Suspense fallback={<LoaderPage />}>
          <Outlet />
        </React.Suspense>
      </Header>
    </ApiProvider>
  );
};

export default AuthenticatedRoute;
